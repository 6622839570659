<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Vehicle } from '@/@core/stores/company'
import { getVehiclesByCompanyId } from '@/@core/stores/company'
import { useVehiclesStore } from '@/@core/stores/vehicle'
import { track } from '@/boot/mixpanel'
import AddNewVehicleDrawer from '@/views/apps/logistics/AddNewVehicleDrawer.vue'
import UpdateVehicleDrawer from '@/views/apps/logistics/UpdateVehicleDrawer.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const searchQuery = ref('')
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const vehicleStore = useVehiclesStore()
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)
const isAddNewVehicleDrawerVisible = ref(false)
const isUpdateVehicleDrawerVisible = ref(false)
const selectedVehicle = ref<Vehicle | null>(null)

const auth = JSON.parse(localStorage.getItem('auth') ?? '{}')
const companyId = auth?.user?.company?.id

const vehiclesData = ref({
  vehicles: [],
  totalVehicles: 0,
})

const filteredVehicles = computed(() => {
  if (!searchQuery.value)
    return vehiclesData.value.vehicles

  return vehiclesData.value.vehicles.filter((vehicle: Vehicle) =>
    vehicle.plate.includes(searchQuery.value) || vehicle.name?.includes(searchQuery.value))
})

const vehicles = computed((): Vehicle[] => filteredVehicles.value)
const totalVehicles = computed(() => filteredVehicles.value.length)

const headers = [
  { title: t('License Plate'), key: 'plate' },
  { title: t('Name'), key: 'name' },
  { title: t('Driver'), key: 'default_driver' },
  { title: t('Actions'), key: 'actions', sortable: false },
]

const showAlert = (type: string, message: string, duration: number = 5000) => {
  alert.value = { type, message }

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration) as unknown as number
}

const loadVehicles = async () => {
  if (!companyId)
    return

  try {
    const fetchedVehicles = await getVehiclesByCompanyId(companyId)

    vehiclesData.value.vehicles = fetchedVehicles
    vehiclesData.value.totalVehicles = vehiclesData.value.vehicles.length
  }
  catch (error) {
    console.error('Error loading vehicles:', error)
  }
}

const openUpdateVehicleDrawer = (vehicle: Vehicle) => {
  selectedVehicle.value = vehicle
  isUpdateVehicleDrawerVisible.value = true
}

const addNewVehicle = async (vehicleData: Omit<Vehicle, 'id' | 'company'>) => {
  try {
    const fullVehicleData = {
      ...vehicleData,
    }

    await vehicleStore.register_company_vehicle(fullVehicleData)

    await loadVehicles()
    showAlert('success', t('Vehicle added successfully.'))
    track('New company vehicle created')
  }
  catch (error) {
    const errorMessage = t('There was a problem adding the new vehicle. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error adding new vehicle:', error)
  }
}

const updateVehicle = async (vehicleData: Partial<Vehicle> & { id: number }) => {
  try {
    const fullVehicleData = {
      id: vehicleData.id,
      plate: vehicleData.plate || selectedVehicle.value?.plate || '',
      name: vehicleData.name || '',
      default_driver_id: vehicleData.default_driver || null,
    }

    const response = await vehicleStore.update_company_vehicle(fullVehicleData)

    if (response?.status === 500) {
      const errorMessage = t('There was a problem updating the vehicle. Please try again later.')

      showAlert('error', errorMessage)
      console.error('Error updating vehicle:', response)
    }
    else {
      await loadVehicles()
      showAlert('success', t('Vehicle updated successfully.'))
      track('Vehicle updated')
    }
  }
  catch (error: any) {
    const errorMessage = t('There was a problem updating the vehicle. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error updating vehicle:', error)
  }
}

const updateOptions = (options: any) => {
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const isMobile = ref(false)

onMounted(() => {
  loadVehicles()
  isMobile.value = window.innerWidth <= 768
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth <= 768
  })
})

onUnmounted(() => {
  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)
})

watch(isAddNewVehicleDrawerVisible, newVal => {
  if (newVal)
    track('Vehicle Add Drawer Opened')
})

watch(isUpdateVehicleDrawerVisible, newVal => {
  if (newVal)
    track('Vehicle Update Drawer Opened')
})
</script>

<template>
  <section>
    <VAlert
      v-if="alert"
      :type="alert.type"
      variant="tonal"
      class="mb-4"
    >
      {{ alert.message }}
    </VAlert>
    <VCard class="mb-6">
      <VToolbar style="background-color: #e6e6e6 !important;">
        <VToolbarTitle
          style="text-transform: uppercase;"
          class="d-flex justify-center ms-0"
        >
          <strong class="d-flex align-center">
            {{ t('Vehicles') }}
          </strong>
        </VToolbarTitle>
      </VToolbar>
      <VCardText
        v-if="!isMobile"
        class="d-flex flex-wrap gap-4"
      >
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <div style="inline-size: 15.625rem;">
            <AppTextField
              v-model="searchQuery"
              :placeholder="t('Vehicles')"
            />
          </div>
          <VBtn
            prepend-icon="tabler-plus"
            @click="isAddNewVehicleDrawerVisible = true"
          >
            {{ t('Add Vehicles') }}
          </VBtn>
        </div>
      </VCardText>

      <VCardText
        v-else
        class="d-flex flex-wrap gap-1"
      >
        <div class="d-flex">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 5rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="d-flex align-center flex-wrap">
          <VBtn
            prepend-icon="tabler-plus"
            style="inline-size: 10.5rem;"
            @click="isAddNewVehicleDrawerVisible = true"
          >
            {{ t('Add Vehicles') }}
          </VBtn>
        </div>

        <div style="inline-size: 100%;">
          <AppTextField
            v-model="searchQuery"
            :placeholder="t('Vehicles')"
          />
        </div>
      </VCardText>

      <VDivider />
      <div
        v-if="!isMobile"
        style="position: relative;"
      >
        <VDataTableServer
          v-model:items-per-page="itemsPerPage"
          v-model:page="page"
          :items="vehicles"
          :items-length="totalVehicles"
          :headers="headers"
          class="text-no-wrap"
          @update:options="updateOptions"
        >
          <template #item.plate="{ item }">
            <div>{{ item.plate }}</div>
          </template>

          <template #item.name="{ item }">
            <div class="d-flex align-center gap-x-4">
              <div class="d-flex flex-column">
                <h6 class="text-base">
                  <RouterLink
                    :to="{ name: 'apps-user-view-id', params: { id: item.id } }"
                    class="font-weight-medium text-link"
                  >
                    {{ item.name }}
                  </RouterLink>
                </h6>
              </div>
            </div>
          </template>

          <template #item.default_driver="{ item }">
            <div class="d-flex align-center gap-x-4">
              <div class="d-flex flex-column">
                <h6 class="text-base">
                  <RouterLink
                    :to="{ name: 'apps-user-view-id', params: { id: item.id } }"
                    class="font-weight-medium text-link"
                  >
                    {{ item.default_driver.name }}
                  </RouterLink>
                </h6>
              </div>
            </div>
          </template>

          <template #item.actions="{ item }">
            <IconBtn @click="openUpdateVehicleDrawer(item)">
              <VIcon icon="tabler-pencil" />
            </IconBtn>
          </template>

          <template #bottom>
            <TablePagination
              v-model:page="page"
              :items-per-page="itemsPerPage"
              :total-items="totalVehicles"
            />
          </template>
        </VDataTableServer>
      </div>
      <div v-else>
        <VCard
          v-for="vehicle in vehicles"
          :key="vehicle.plate"
          class="driver-card"
        >
          <div class="card-header">
            <VCardTitle class="driver-card-title">
              {{ vehicle.plate }}
            </VCardTitle>
            <VBtn
              prepend-icon="tabler-pencil"
              class="edit-button"
              @click="openUpdateVehicleDrawer(vehicle)"
            />
          </div>
          <VCardText class="driver-card-text">
            <p>
              <strong>
                <VIcon icon="tabler-truck" />
              </strong>
              {{ vehicle.name }}
            </p>
            <p>
              <strong>
                <VIcon icon="tabler-user" />
              </strong>
              {{ vehicle.default_driver?.name || t('No driver assigned') }}
            </p>
          </VCardText>
        </VCard>
      </div>
    </VCard>

    <AddNewVehicleDrawer
      v-model:isDrawerOpen="isAddNewVehicleDrawerVisible"
      @vehicle-data="addNewVehicle"
    />
    <UpdateVehicleDrawer
      v-model:isDrawerOpen="isUpdateVehicleDrawerVisible"
      :vehicle="selectedVehicle"
      @vehicle-data="updateVehicle"
    />
  </section>
</template>

<style lang="scss">
.driver-card {
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
  margin-block: 1rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.driver-card-text {
  padding: 0;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 0.5rem;
}

.driver-card-title {
  flex-grow: 1;
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  block-size: 40px;
  inline-size: 40px;
  min-inline-size: 40px !important;
  padding-inline: 10px 0;
}
</style>
